<template>
  <v-app>
    <DashboardHeader />
    <DashboardNavigation />
    <LayoutMessages />
    <v-main
      class="horizontal-hidden-scroll"
      style="padding-left: 56px; overflow-y: hidden"
    >
      <DashboardAnnouncements />
      <!-- TODO: SOLVE DIFFERENTLY: DashboardStatusChecker /-->
      <template v-if="isAdminPage">
        <div
          v-if="!showLoader"
          :class="{
            white: !$route.meta['disableWhiteBackground'],
          }"
          style="height: calc(100vh - 70px)"
        >
          <slot />
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center align-center white"
          style="height: calc(100vh - 70px)"
        >
          <v-progress-circular
            :size="130"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <p class="mt-8">We are loading your data, please hold on...</p>
        </div>
      </template>
      <v-container
        v-else
        class="px-0 py-0"
        :class="{
          'px-0': $route.meta['disablePadding'],
          'py-0': $route.meta['disablePadding'],
          'px-6': !$route.meta['disablePadding'],
          'py-6': !$route.meta['disablePadding'],
        }"
        fluid
        style="height: calc(100vh - 64px)"
        :style="{
          overflow: $route.meta['disableScroll'] ? 'hidden' : 'auto',
        }"
      >
        <template v-if="!showLoader">
          <slot />
        </template>

        <div
          v-else
          class="d-flex flex-column justify-center align-center"
          style="height: calc(100vh - (70px + 32px + 32px))"
        >
          <v-progress-circular
            :size="130"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <p class="mt-8">We are loading your data, please hold on...</p>
        </div>
      </v-container>
      <Support />
      <UsersSurvey />
    </v-main>
  </v-app>
</template>
<script>
import DashboardNavigation from "@/components/Dashboard/Navigation/Navigation";
import LayoutMessages from "@/components/LayoutMessages";
import DashboardHeader from "@/components/Dashboard/DashboardHeader";
import DashboardAnnouncements from "@/components/Dashboard/DashboardAnnouncements";
//import DashboardStatusChecker from "@/Components/Dashboard/DashboardStatusChecker";
import Support from "@/components/Support.vue";
import UsersSurvey from "../components/Users/Survey/UsersSurvey.vue";

export default {
  data() {
    return {
      loading: true,
      interval: null,
    };
  },
  name: "DashboardLayout",
  components: {
    DashboardNavigation,
    //DashboardStatusChecker,
    LayoutMessages,
    Support,
    UsersSurvey,
    DashboardHeader,
    DashboardAnnouncements,
  },
  computed: {
    showLoader() {
      return this.$store.getters["showLoader"];
    },
    /**
     * Is admin page
     */
    isAdminPage() {
      return this.$route.path.includes("admin");
    },
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  },
  async mounted() {
    this.$store.dispatch("auth/setTokensFromLocalStorage");
    this.loading = true;
    await Promise.all([
      this.$store.dispatch("auth/refreshCalendlyBooking"),
      this.$store.dispatch("auth/fetchUserData"),
      this.$store.dispatch("fetchBetaFeaturesAccesses"),
      this.$store.dispatch("fetchUnseenRepliesCount"),
      this.$store.dispatch("doppelganger/refreshDoppelganger"),
    ]);

    this.loading = false;
  },
};
</script>

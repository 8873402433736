import axios from "@/plugins/axios";

/**
 * Initialize current user account
 * @returns {Promise<undefined|*>}
 */
export async function initializeCurrentUserAccount() {
  try {
    const response = await axios.put("/users/me/initialize");

    if (!response || !response["success"] || !response["user"]) {
      return undefined;
    }

    return response["user"];
  } catch (error) {
    console.error(
      "[users@initializeCurrentUserAccount] During initialization of user account error was throw",
      error
    );
    return undefined;
  }
}

/**
 * Get users document by their ID
 * @param {string} id
 * @returns {Promise<undefined|object>}
 */
export async function getUserById(id) {
  try {
    const response = await axios.get(`/users/${id}`);

    if (!response || !response["success"] || !response["user"]) {
      return undefined;
    }

    return response["user"];
  } catch (error) {
    console.error(
      "[users@getMe] Error was thrown during fetching current user document",
      error
    );
    return undefined;
  }
}

/**
 * Get current user information
 * @returns { Promise<{user: object | undefined, status: object | undefined}> }
 */
export async function getMe() {
  try {
    const response = await axios.get("/users/me");

    if (!response || !response["success"] || !response["user"]) {
      return { user: undefined, status: undefined };
    }

    return { user: response["user"], status: response["status"] };
  } catch (error) {
    console.error(
      "[users@getMe] Error was thrown during fetching current user document",
      error
    );
    return { user: undefined, status: undefined };
  }
}

/**
 * Returns current user survey
 * @returns {Promise<undefined|object>}
 */
export async function getCurrentUserSurvey() {
  try {
    const response = await axios.get("/users/me/survey");

    if (!response || !response["success"] || !response["survey"]) {
      return undefined;
    }

    return response["survey"];
  } catch (error) {
    console.error(
      "[users@getCurrentUserSurvey] Error was thrown when fetching user survey",
      error
    );
    return undefined;
  }
}

/**
 * Update current user survey
 * @param {Record<string, string>} survey
 * @returns {Promise<Object|undefined>}
 */
export async function updateCurrentUserSurvey(survey) {
  try {
    const response = await axios.post("/users/me/survey", survey);

    if (!response || !response["success"] || !response["user"]) {
      return undefined;
    }

    return response["user"]["userSurvey"];
  } catch (error) {
    console.error(
      "[users@updateCurrentUserSurvey] During update of the survey error was thrown",
      error
    );
    return undefined;
  }
}

/**
 * Get info about the Beta access for the current user
 * @returns {Promise<undefined|string[]>}
 */
export async function getCurrentUserBetaAccess() {
  try {
    const response = await axios.get("/users/me/beta-accesses");

    if (!response || !response["success"] || !response["accesses"]) {
      return undefined;
    }

    return response["accesses"];
  } catch (error) {
    console.error(
      "[users@getCurrentUserBetaAccess] We failed to fetch current user beta access information",
      error
    );
    return undefined;
  }
}

/**
 * Returns user on public endpoints.
 * @param {string} link
 * @returns {Promise<undefined|Object>}
 */
export async function getUserByLinkPublic(link) {
  try {
    const response = await axios.get(`/public/user/${link}`);

    if (!response || !response["success"] || !response["user"]) {
      return undefined;
    }

    return response["user"];
  } catch (error) {
    console.error(
      `[users@getUserByLinkPublic] We failed to fetch user by link ${link}`,
      error
    );
    return undefined;
  }
}
